<template>
  <div class="plan-wrap">
    <div class="plan-table-wrap">
      <div
        v-for="(item, index) in priceInfo"
        :key="index"
        :class="['plan-item', { title: item.title }, { header: item.html }]"
      >
        <template v-if="item.html">
          <span class="plan-item-col title">
            <!-- <span class="plan-btn-wrap">
              <span
                :class="['plan-btn', { active: price_type === 'year' }]"
                @click="price_type = 'year'"
                >按年</span
              >
              <span
                :class="['plan-btn', { active: price_type === 'month' }]"
                @click="price_type = 'month'"
                >按月</span
              >
            </span>
            <span class="plan-desc">按年订阅享 {{ discount * 10 }} 折优惠</span> -->
          </span>
          <span class="plan-item-col free">
            <span class="plan-type">{{$t('price.free')}}</span>
          </span>
          <span class="plan-item-col professional" :class="{ noOriginal: !price.professional[price_type].original }">
            <span class="plan-type">{{$t('price.speciality')}}</span>
            <span class="plan-price">
              <span>{{ price.professional[price_type].real }}</span>
              <span>{{ price.professional[price_type].unit }}</span>
              <!-- <span
                v-if="price.professional[price_type].original"
                style="
                  margin-left: 10px;
                  color: #126051;
                  font-size: 14px;
                  text-decoration: line-through;
                "
                >{{ price.professional[price_type].original }}</span
              > -->
            </span>
            <span
              @click="toSubscrible('professional')"
              class="plan-update"
              v-if="roles !== 'enterprise' && roles !== 'qihoo'"
              data-en="professional"
              >{{ roles === 'professional' ? $t('price.renew') : $t('price.upgrad') }}</span
            >
          </span>
          <span class="plan-item-col enterprise" :class="{ noOriginal: !price.enterprise[price_type].original }">
            <span class="plan-type">{{$t('price.professional')}}</span>
            <span class="plan-price">
              <span>{{ price.enterprise[price_type].real }}</span>
              <span style="font-size: 12px; margin-left: 4px">{{
                price.enterprise[price_type].unit
              }}</span>
              <!-- <span
                v-if="price.enterprise[price_type].original"
                style="
                  margin-left: 10px;
                  color: #766a54;
                  font-size: 14px;
                  text-decoration: line-through;
                "
                >{{ price.enterprise[price_type].original }}</span
              > -->
            </span>
            <span
              @click="toSubscrible('enterprise')"
              class="plan-update"
              data-en="enterprise"
              v-if="roles !== 'qihoo'"
              >{{ roles !== 'enterprise' ? $t('price.upgrad') : $t('price.renew') }}</span
            >
          </span>
          <span class="plan-item-col enterprisepro">
            <span class="plan-type">{{ $t('price.enterprise') }}</span>
            <router-link
              tag="span"
              class="plan-update"
              :to="{ name: 'consulting' }"
            >
            {{ $t('price.contact_us') }}
            </router-link>
          </span>
        </template>
        <template v-else>
          <span class="plan-item-col title">
            <span :class="{ rowTitle: !item.desc }">{{ item.name }}</span>
            <span class="info" v-if="item.desc">
              <el-tooltip
                popper-class="plan-info-tips"
                :effect="$isDark() ? 'dark' : 'light'"
                :content="item.desc"
                placement="right"
              >
                <!-- <img src="@/assets/img/svg/info.svg" alt /> -->
                <span class="tishi-wrap">
                  <svg-icon name="tishi"></svg-icon>
                </span>
              </el-tooltip>
            </span>
          </span>
          <span v-for="role in rolelist" :key="role" :class="['plan-item-col', role]">
            <span v-if="getItemStatus(item[role])" class="desc">{{ item[role] }}</span>
            <span v-else class="pic">
              <img :src="getStatus(item[role])" />
            </span>
          </span>
        </template>
      </div>
    </div>
    <app-footer></app-footer>
    <to-order-dialog @closeDialog="closeToOrderDialog" v-if="showLog"></to-order-dialog>
    <backTo-top isContainer=".plan-wrap"></backTo-top>
  </div>
</template>

<script setup>
import priceInfo from './config'
import toOrderDialog from '@/components/order/dialog.vue'
import AppFooter from '@/components/app/app-footer'
import { upgradeAction } from '@/hooks/useUpgrade'
import { getPaymentOrderCheck, getPaymentOrderPriceShow } from '@/services/upgrade-service.js'
import { useStore } from 'vuex'
import { computed, ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'

const store = useStore()
const router = useRouter()

const price = ref({
  professional: {
    year: {},
    month: {}
  },
  enterprise: {
    year: {},
    month: {}
  }
})

const rolelist = ['free', 'professional', 'enterprise', 'enterprisepro']
const discount = ref(1)
const price_type = ref('month')
const showLog = ref(false)

const getStatus = (type) => {
  const src = type ? 'checked' : 'unchecked'
  return require(`@/assets/img/svg/${src}.svg`)
}

const getItemStatus = (type) => {
  return typeof type !== 'boolean'
}

const toSubscrible = (mealType) => {
  upgradeAction({
    cb: () => {
      getPaymentOrderCheck().then((res) => {
        if (res.status) {
          showLog.value = true
          return
        }
        showLog.value = false
        const query = {
          type: ''
        }
        if (roles.value === 'free') {
          query.type = 'purchase'
          query.meal_type = mealType
        } else if (roles.value === 'professional') {
          query.type = mealType === roles.value ? 'renew' : 'upgrade'
          if (query.type === 'upgrade') {
            query.group = 'enterprise'
          }
        } else if (roles.value === 'enterprise') {
          query.type = 'renew'
        }
        if (query.type !== 'upgrade') {
          query.price_type = price_type.value === 'year' ? 'one_year' : 'one_month'
        }
        router.push({ name: 'subscribe', query: query })
      })
    }
  })
}

const getData = () => {
  getPaymentOrderPriceShow().then((res) => {
    if (res.status === 0) {
      price.value = res.price
      discount.value = res.discount.toFixed(2) || 1
    }
  })
}
const closeToOrderDialog = (flag) => {
  showLog.value = false
  if (!flag) return
  router.push({
    name: 'order'
  })
}
const roles = computed(() => {
  console.log(store.getters.roles)
  return store.getters.roles
})

onMounted(() => {
  // store.dispatch('user/getUserInfo')
  getData()
})
</script>

<style lang="scss" scoped>
@import '@/styles/common.scss';
.plan-wrap {
  height: calc(100vh - 56px);
  overflow-y: auto;
  .plan-table-wrap {
    padding: 24px 40px;
    position: relative;
    .plan-item {
      line-height: 48px;
      font-size: 14px;
      display: flex;
      border-bottom: 1px solid var(--color-border-2);
      .plan-item-col {
        display: inline-flex;
        flex: 1;
        box-sizing: border-box;
        padding: 0 16px;
        color: var(--color-text-2);
        &:not(:first-child) {
          text-align: center;
          justify-content: center;
          align-items: center;
        }
        &.title {
          position: relative;
          flex: 1.1;
          .info {
            display: none;
            margin-left: 5px;
            color: #00b672;
          }
          .rowTitle {
            color: var(--color-text-2);
            font-weight: 600;
          }
        }
      }
      &:hover {
        .plan-item-col {
          &.title {
            .info {
              display: flex;
            }
          }
        }
      }
      &:nth-child(odd) {
        background: var(--detect-config-bg);
      }
      &:nth-child(even) {
        background: var(--detect-config-bg);
      }
      &.title {
        background: #303445;
        box-shadow: 0 -1px 0 0 #3f4251;
        .plan-item-col {
          &:not(:first-child) {
            border-left: 0;
          }
        }
      }
      &.header {
        background: var(--color-bg-3);
        border-bottom: 1px solid var(--color-border-2);
        line-height: normal;
        .plan-item-col {
          display: inline-flex;
          height: 120px;
          justify-content: center;
          flex-direction: column;
          align-items: center;
          font-size: 18px;
          .plan-type {
            font-weight: 600;
          }
          &.title {
            .plan-btn-wrap {
              width: 96px;
              height: 28px;
              box-sizing: border-box;
              border: 1px solid #05966f;
              border-radius: 13px;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 12px;
              color: #C4CADA;
              margin-bottom: 6px;
              .plan-btn {
                height: 22px;
                line-height: 24px;
                width: calc(50% - 2px);
                display: inline-block;
                text-align: center;
                border-radius: 13px;
                cursor: pointer;
                &.active {
                  color: var(--color-text-1);
                  background-color: #05966f;
                }
              }
            }
            .plan-desc {
              font-size: 14px;
              color: var(--color-text-3);
            }
          }
          &.professional, &.enterprise, &.enterprisepro {
            .plan-price {
              margin: 4px 0 9px 0;
              span:nth-child(1) {
                font-size: 18px;
              }
              span:nth-child(2) {
                font-size: 12px;
                margin-left: 4px;
              }
            }
            .plan-update {
              display: inline-block;
              // width: 88px;
              padding: 0 10px;
              height: 32px;
              line-height: 30px;
              box-sizing: border-box;
              border-radius: 16px;
              cursor: pointer;
              font-size: 14px;
              border: 1px solid;
            }
          }
          &.professional, &.enterprise {
            position: relative;
            // &::after {
            //   content: '\9650\65f6\4f18\60e0';
            //   position: absolute;
            //   font-size: 12px;
            //   line-height: 22px;
            //   top: 0px;
            //   right: 0;
            //   width: 59px;
            //   height: 22px;
            //   background: #ff7670;
            //   border: 1px solid #cf3e4a;
            //   border-radius: 11px 0px 11px 0px;
            //   color: var(--color-text-1);
            // }
            // &.noOriginal {
            //   &::after {
            //     content: none;
            //     width: 0;
            //     height: 0;
            //   }
            // }
          }
          &.professional {
            color: #00b682;
          }
          &.enterprise {
            color: #eac998;
            .plan-type {
              color: #f9daac;
            }
          }
          &.enterprisepro {
            color: #eac998;
            .plan-update {
              margin-top: 6px;
              // width: 88px;
              padding: 0 10px;
              height: 32px;
              line-height: 30px;
            }
          }
        }
      }
    }
  }
}

[data-theme='light'] {
  .plan-wrap .plan-table-wrap .plan-item.title {
    background: #f8f9fa;
    box-shadow: 0 -1px 0 0 #f8f9fa;
  }
  .plan-wrap .plan-table-wrap .plan-item.header {
    background: #ffffff;
  }
  .plan-wrap .plan-table-wrap .plan-item.header .plan-item-col.title .plan-btn-wrap {
    color: #525966;
  }
  .plan-wrap .plan-table-wrap .plan-item.header .plan-item-col.title .plan-btn-wrap .plan-btn.active {
    color: #fff;
  }
  .plan-wrap .plan-table-wrap .plan-item .plan-item-col.free {
    color: #242933;
  }
  .plan-wrap .plan-table-wrap .plan-item.header .plan-item-col.professional {
    color: #00AB7A;
  }
  .plan-wrap .plan-table-wrap .plan-item.header .plan-item-col.professional .plan-price {
    color: #00B682;
  }
  .plan-wrap .plan-table-wrap .plan-item.header .plan-item-col.professional .plan-update {
    color: #00B682;
    border: 1px solid #05966F;
  }
  .plan-wrap .plan-table-wrap .plan-item.header .plan-item-col.enterprise .plan-type {
    color: #DF9E31;
  }
  .plan-wrap .plan-table-wrap .plan-item.header .plan-item-col.enterprise .plan-price {
    color: #DF9E31;
  }
  .plan-wrap .plan-table-wrap .plan-item.header .plan-item-col.enterprise .plan-update {
    color: #DF9E31;
    border: 1px solid #EABB6D;
  }
  .plan-wrap .plan-table-wrap .plan-item.header .plan-item-col.enterprisepro .plan-type {
    color: #DF9E31;
  }
  .plan-wrap .plan-table-wrap .plan-item.header .plan-item-col.enterprisepro .plan-update {
    color: #DF9E31;
    border: 1px solid #EABB6D;
  }
}
</style>
